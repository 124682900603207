/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * API
 * API для Vigbo.Gallery
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { useMutation, useQuery } from "@tanstack/react-query"

import type { BodyType, ErrorType } from "../../api/api-instance"
import { apiInstance } from "../../api/api-instance"
import type {
  DeleteAppFavlistRemovephotofromlistBody,
  FavList,
  FavList2,
  FavList3,
  GetAppFavlistGetcustomers200Item,
  GetAppFavlistGetforphoto200Item,
  GetPublicPhotosDownloadTypeParams,
  PatchAppFavlistMarkfavlistsasviewedBody,
  PatchAppFavlistUpdateBody,
  PostAppFavlistAddcommentBody,
  PostAppFavlistAddphototolistBody,
  PostAppFavlistCreateBody,
} from "../../models"

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const postAppFavlistCreate = (
  postAppFavlistCreateBody: BodyType<PostAppFavlistCreateBody>,
  options?: SecondParameter<typeof apiInstance>
) => {
  return apiInstance<void>(
    {
      url: `/api/v1/lists`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postAppFavlistCreateBody,
    },
    options
  )
}

export const getPostAppFavlistCreateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAppFavlistCreate>>,
    TError,
    { data: BodyType<PostAppFavlistCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAppFavlistCreate>>,
  TError,
  { data: BodyType<PostAppFavlistCreateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAppFavlistCreate>>,
    { data: BodyType<PostAppFavlistCreateBody> }
  > = (props) => {
    const { data } = props ?? {}

    return postAppFavlistCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostAppFavlistCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postAppFavlistCreate>>>
export type PostAppFavlistCreateMutationBody = BodyType<PostAppFavlistCreateBody>
export type PostAppFavlistCreateMutationError = ErrorType<void>

export const usePostAppFavlistCreate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAppFavlistCreate>>,
    TError,
    { data: BodyType<PostAppFavlistCreateBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postAppFavlistCreate>>,
  TError,
  { data: BodyType<PostAppFavlistCreateBody> },
  TContext
> => {
  const mutationOptions = getPostAppFavlistCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Избранное клиента по галерее
 */
export const getAppFavlistGetbygalleryforcurrentclient = (
  gallery: string,
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<FavList>({ url: `/api/v1/lists/gallery/${gallery}/my`, method: "GET", signal }, options)
}

export const getGetAppFavlistGetbygalleryforcurrentclientQueryKey = (gallery: string) => {
  return [`/api/v1/lists/gallery/${gallery}/my`] as const
}

export const getGetAppFavlistGetbygalleryforcurrentclientQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>,
  TError = ErrorType<void>
>(
  gallery: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppFavlistGetbygalleryforcurrentclientQueryKey(gallery)
  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>> = ({ signal }) =>
    getAppFavlistGetbygalleryforcurrentclient(gallery, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!gallery, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAppFavlistGetbygalleryforcurrentclientQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>
>
export type GetAppFavlistGetbygalleryforcurrentclientQueryError = ErrorType<void>

/**
 * @summary Избранное клиента по галерее
 */

export function useGetAppFavlistGetbygalleryforcurrentclient<
  TData = Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>,
  TError = ErrorType<void>
>(
  gallery: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetbygalleryforcurrentclient>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppFavlistGetbygalleryforcurrentclientQueryOptions(gallery, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAppFavlistGetlistphotosnames = (
  id: string,
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<void>({ url: `/api/v1/lists/${id}/copy`, method: "GET", signal }, options)
}

export const getGetAppFavlistGetlistphotosnamesQueryKey = (id: string) => {
  return [`/api/v1/lists/${id}/copy`] as const
}

export const getGetAppFavlistGetlistphotosnamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppFavlistGetlistphotosnamesQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>> = ({ signal }) =>
    getAppFavlistGetlistphotosnames(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAppFavlistGetlistphotosnamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>
>
export type GetAppFavlistGetlistphotosnamesQueryError = ErrorType<unknown>

export function useGetAppFavlistGetlistphotosnames<
  TData = Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetlistphotosnames>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppFavlistGetlistphotosnamesQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const patchAppFavlistMarkfavlistsasviewed = (
  patchAppFavlistMarkfavlistsasviewedBody: BodyType<PatchAppFavlistMarkfavlistsasviewedBody>,
  options?: SecondParameter<typeof apiInstance>
) => {
  return apiInstance<void>(
    {
      url: `/api/v1/lists/mark-all-as-viewed`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchAppFavlistMarkfavlistsasviewedBody,
    },
    options
  )
}

export const getPatchAppFavlistMarkfavlistsasviewedMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAppFavlistMarkfavlistsasviewed>>,
    TError,
    { data: BodyType<PatchAppFavlistMarkfavlistsasviewedBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAppFavlistMarkfavlistsasviewed>>,
  TError,
  { data: BodyType<PatchAppFavlistMarkfavlistsasviewedBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAppFavlistMarkfavlistsasviewed>>,
    { data: BodyType<PatchAppFavlistMarkfavlistsasviewedBody> }
  > = (props) => {
    const { data } = props ?? {}

    return patchAppFavlistMarkfavlistsasviewed(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchAppFavlistMarkfavlistsasviewedMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAppFavlistMarkfavlistsasviewed>>
>
export type PatchAppFavlistMarkfavlistsasviewedMutationBody = BodyType<PatchAppFavlistMarkfavlistsasviewedBody>
export type PatchAppFavlistMarkfavlistsasviewedMutationError = ErrorType<void>

export const usePatchAppFavlistMarkfavlistsasviewed = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAppFavlistMarkfavlistsasviewed>>,
    TError,
    { data: BodyType<PatchAppFavlistMarkfavlistsasviewedBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchAppFavlistMarkfavlistsasviewed>>,
  TError,
  { data: BodyType<PatchAppFavlistMarkfavlistsasviewedBody> },
  TContext
> => {
  const mutationOptions = getPatchAppFavlistMarkfavlistsasviewedMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getPublicListId = (id: string, options?: SecondParameter<typeof apiInstance>, signal?: AbortSignal) => {
  return apiInstance<FavList2>({ url: `/api/v1/lists/${id}`, method: "GET", signal }, options)
}

export const getGetPublicListIdQueryKey = (id: string) => {
  return [`/api/v1/lists/${id}`] as const
}

export const getGetPublicListIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublicListId>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicListId>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPublicListIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicListId>>> = ({ signal }) =>
    getPublicListId(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicListId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPublicListIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicListId>>>
export type GetPublicListIdQueryError = ErrorType<unknown>

export function useGetPublicListId<TData = Awaited<ReturnType<typeof getPublicListId>>, TError = ErrorType<unknown>>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicListId>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPublicListIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const deleteAppFavlistDelete = (id: string, options?: SecondParameter<typeof apiInstance>) => {
  return apiInstance<void>({ url: `/api/v1/lists/${id}`, method: "DELETE" }, options)
}

export const getDeleteAppFavlistDeleteMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAppFavlistDelete>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteAppFavlistDelete>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAppFavlistDelete>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteAppFavlistDelete(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteAppFavlistDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAppFavlistDelete>>>

export type DeleteAppFavlistDeleteMutationError = ErrorType<unknown>

export const useDeleteAppFavlistDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAppFavlistDelete>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<Awaited<ReturnType<typeof deleteAppFavlistDelete>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteAppFavlistDeleteMutationOptions(options)

  return useMutation(mutationOptions)
}
export const patchAppFavlistUpdate = (
  id: string,
  patchAppFavlistUpdateBody: BodyType<PatchAppFavlistUpdateBody>,
  options?: SecondParameter<typeof apiInstance>
) => {
  return apiInstance<void>(
    {
      url: `/api/v1/lists/${id}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchAppFavlistUpdateBody,
    },
    options
  )
}

export const getPatchAppFavlistUpdateMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAppFavlistUpdate>>,
    TError,
    { id: string; data: BodyType<PatchAppFavlistUpdateBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAppFavlistUpdate>>,
  TError,
  { id: string; data: BodyType<PatchAppFavlistUpdateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAppFavlistUpdate>>,
    { id: string; data: BodyType<PatchAppFavlistUpdateBody> }
  > = (props) => {
    const { id, data } = props ?? {}

    return patchAppFavlistUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchAppFavlistUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof patchAppFavlistUpdate>>>
export type PatchAppFavlistUpdateMutationBody = BodyType<PatchAppFavlistUpdateBody>
export type PatchAppFavlistUpdateMutationError = ErrorType<void>

export const usePatchAppFavlistUpdate = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAppFavlistUpdate>>,
    TError,
    { id: string; data: BodyType<PatchAppFavlistUpdateBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchAppFavlistUpdate>>,
  TError,
  { id: string; data: BodyType<PatchAppFavlistUpdateBody> },
  TContext
> => {
  const mutationOptions = getPatchAppFavlistUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postAppFavlistAddphototolist = (
  id: string,
  postAppFavlistAddphototolistBody: BodyType<PostAppFavlistAddphototolistBody>,
  options?: SecondParameter<typeof apiInstance>
) => {
  return apiInstance<void>(
    {
      url: `/api/v1/lists/${id}/photos`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postAppFavlistAddphototolistBody,
    },
    options
  )
}

export const getPostAppFavlistAddphototolistMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAppFavlistAddphototolist>>,
    TError,
    { id: string; data: BodyType<PostAppFavlistAddphototolistBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAppFavlistAddphototolist>>,
  TError,
  { id: string; data: BodyType<PostAppFavlistAddphototolistBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAppFavlistAddphototolist>>,
    { id: string; data: BodyType<PostAppFavlistAddphototolistBody> }
  > = (props) => {
    const { id, data } = props ?? {}

    return postAppFavlistAddphototolist(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostAppFavlistAddphototolistMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAppFavlistAddphototolist>>
>
export type PostAppFavlistAddphototolistMutationBody = BodyType<PostAppFavlistAddphototolistBody>
export type PostAppFavlistAddphototolistMutationError = ErrorType<void>

export const usePostAppFavlistAddphototolist = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAppFavlistAddphototolist>>,
    TError,
    { id: string; data: BodyType<PostAppFavlistAddphototolistBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postAppFavlistAddphototolist>>,
  TError,
  { id: string; data: BodyType<PostAppFavlistAddphototolistBody> },
  TContext
> => {
  const mutationOptions = getPostAppFavlistAddphototolistMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteAppFavlistRemovephotofromlist = (
  id: string,
  deleteAppFavlistRemovephotofromlistBody: BodyType<DeleteAppFavlistRemovephotofromlistBody>,
  options?: SecondParameter<typeof apiInstance>
) => {
  return apiInstance<void>(
    {
      url: `/api/v1/lists/${id}/photos`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      data: deleteAppFavlistRemovephotofromlistBody,
    },
    options
  )
}

export const getDeleteAppFavlistRemovephotofromlistMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAppFavlistRemovephotofromlist>>,
    TError,
    { id: string; data: BodyType<DeleteAppFavlistRemovephotofromlistBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAppFavlistRemovephotofromlist>>,
  TError,
  { id: string; data: BodyType<DeleteAppFavlistRemovephotofromlistBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAppFavlistRemovephotofromlist>>,
    { id: string; data: BodyType<DeleteAppFavlistRemovephotofromlistBody> }
  > = (props) => {
    const { id, data } = props ?? {}

    return deleteAppFavlistRemovephotofromlist(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteAppFavlistRemovephotofromlistMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAppFavlistRemovephotofromlist>>
>
export type DeleteAppFavlistRemovephotofromlistMutationBody = BodyType<DeleteAppFavlistRemovephotofromlistBody>
export type DeleteAppFavlistRemovephotofromlistMutationError = ErrorType<void>

export const useDeleteAppFavlistRemovephotofromlist = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAppFavlistRemovephotofromlist>>,
    TError,
    { id: string; data: BodyType<DeleteAppFavlistRemovephotofromlistBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAppFavlistRemovephotofromlist>>,
  TError,
  { id: string; data: BodyType<DeleteAppFavlistRemovephotofromlistBody> },
  TContext
> => {
  const mutationOptions = getDeleteAppFavlistRemovephotofromlistMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getPublicPhotosDownloadType = (
  type: string,
  params?: GetPublicPhotosDownloadTypeParams,
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<void>({ url: `/api/v1/lists/photos/download/${type}`, method: "GET", params, signal }, options)
}

export const getGetPublicPhotosDownloadTypeQueryKey = (type: string, params?: GetPublicPhotosDownloadTypeParams) => {
  return [`/api/v1/lists/photos/download/${type}`, ...(params ? [params] : [])] as const
}

export const getGetPublicPhotosDownloadTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublicPhotosDownloadType>>,
  TError = ErrorType<unknown>
>(
  type: string,
  params?: GetPublicPhotosDownloadTypeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicPhotosDownloadType>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPublicPhotosDownloadTypeQueryKey(type, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicPhotosDownloadType>>> = ({ signal }) =>
    getPublicPhotosDownloadType(type, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!type, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicPhotosDownloadType>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPublicPhotosDownloadTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPublicPhotosDownloadType>>
>
export type GetPublicPhotosDownloadTypeQueryError = ErrorType<unknown>

export function useGetPublicPhotosDownloadType<
  TData = Awaited<ReturnType<typeof getPublicPhotosDownloadType>>,
  TError = ErrorType<unknown>
>(
  type: string,
  params?: GetPublicPhotosDownloadTypeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicPhotosDownloadType>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPublicPhotosDownloadTypeQueryOptions(type, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAppFavlistGetforphoto = (
  id: string,
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<GetAppFavlistGetforphoto200Item[]>(
    { url: `/api/v1/lists/photo/${id}/lists`, method: "GET", signal },
    options
  )
}

export const getGetAppFavlistGetforphotoQueryKey = (id: string) => {
  return [`/api/v1/lists/photo/${id}/lists`] as const
}

export const getGetAppFavlistGetforphotoQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppFavlistGetforphoto>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetforphoto>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppFavlistGetforphotoQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppFavlistGetforphoto>>> = ({ signal }) =>
    getAppFavlistGetforphoto(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppFavlistGetforphoto>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAppFavlistGetforphotoQueryResult = NonNullable<Awaited<ReturnType<typeof getAppFavlistGetforphoto>>>
export type GetAppFavlistGetforphotoQueryError = ErrorType<unknown>

export function useGetAppFavlistGetforphoto<
  TData = Awaited<ReturnType<typeof getAppFavlistGetforphoto>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetforphoto>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppFavlistGetforphotoQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAppFavlistIndex = (id: string, options?: SecondParameter<typeof apiInstance>, signal?: AbortSignal) => {
  return apiInstance<FavList3[]>({ url: `/api/v1/lists/gallery/${id}/lists`, method: "GET", signal }, options)
}

export const getGetAppFavlistIndexQueryKey = (id: string) => {
  return [`/api/v1/lists/gallery/${id}/lists`] as const
}

export const getGetAppFavlistIndexQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppFavlistIndex>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistIndex>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppFavlistIndexQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppFavlistIndex>>> = ({ signal }) =>
    getAppFavlistIndex(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppFavlistIndex>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAppFavlistIndexQueryResult = NonNullable<Awaited<ReturnType<typeof getAppFavlistIndex>>>
export type GetAppFavlistIndexQueryError = ErrorType<unknown>

export function useGetAppFavlistIndex<
  TData = Awaited<ReturnType<typeof getAppFavlistIndex>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistIndex>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppFavlistIndexQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getAppFavlistGetcustomers = (
  gallery: string,
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<GetAppFavlistGetcustomers200Item[]>(
    { url: `/api/v1/lists/gallery/${gallery}/clients`, method: "GET", signal },
    options
  )
}

export const getGetAppFavlistGetcustomersQueryKey = (gallery: string) => {
  return [`/api/v1/lists/gallery/${gallery}/clients`] as const
}

export const getGetAppFavlistGetcustomersQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppFavlistGetcustomers>>,
  TError = ErrorType<unknown>
>(
  gallery: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetcustomers>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppFavlistGetcustomersQueryKey(gallery)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppFavlistGetcustomers>>> = ({ signal }) =>
    getAppFavlistGetcustomers(gallery, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!gallery, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppFavlistGetcustomers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAppFavlistGetcustomersQueryResult = NonNullable<Awaited<ReturnType<typeof getAppFavlistGetcustomers>>>
export type GetAppFavlistGetcustomersQueryError = ErrorType<unknown>

export function useGetAppFavlistGetcustomers<
  TData = Awaited<ReturnType<typeof getAppFavlistGetcustomers>>,
  TError = ErrorType<unknown>
>(
  gallery: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetcustomers>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppFavlistGetcustomersQueryOptions(gallery, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getPublicListDownloadPhotosType = (
  id: string,
  type: string,
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<void>({ url: `/api/v1/lists/${id}/download-photos/${type}/`, method: "GET", signal }, options)
}

export const getGetPublicListDownloadPhotosTypeQueryKey = (id: string, type: string) => {
  return [`/api/v1/lists/${id}/download-photos/${type}/`] as const
}

export const getGetPublicListDownloadPhotosTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>,
  TError = ErrorType<unknown>
>(
  id: string,
  type: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPublicListDownloadPhotosTypeQueryKey(id, type)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>> = ({ signal }) =>
    getPublicListDownloadPhotosType(id, type, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(id && type), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPublicListDownloadPhotosTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>
>
export type GetPublicListDownloadPhotosTypeQueryError = ErrorType<unknown>

export function useGetPublicListDownloadPhotosType<
  TData = Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>,
  TError = ErrorType<unknown>
>(
  id: string,
  type: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPublicListDownloadPhotosType>>, TError, TData>
    request?: SecondParameter<typeof apiInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPublicListDownloadPhotosTypeQueryOptions(id, type, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const postAppFavlistAddcomment = (
  id: string,
  postAppFavlistAddcommentBody: BodyType<PostAppFavlistAddcommentBody>,
  options?: SecondParameter<typeof apiInstance>
) => {
  return apiInstance<void>(
    {
      url: `/api/v1/lists/${id}/comments`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postAppFavlistAddcommentBody,
    },
    options
  )
}

export const getPostAppFavlistAddcommentMutationOptions = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAppFavlistAddcomment>>,
    TError,
    { id: string; data: BodyType<PostAppFavlistAddcommentBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAppFavlistAddcomment>>,
  TError,
  { id: string; data: BodyType<PostAppFavlistAddcommentBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAppFavlistAddcomment>>,
    { id: string; data: BodyType<PostAppFavlistAddcommentBody> }
  > = (props) => {
    const { id, data } = props ?? {}

    return postAppFavlistAddcomment(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostAppFavlistAddcommentMutationResult = NonNullable<Awaited<ReturnType<typeof postAppFavlistAddcomment>>>
export type PostAppFavlistAddcommentMutationBody = BodyType<PostAppFavlistAddcommentBody>
export type PostAppFavlistAddcommentMutationError = ErrorType<void>

export const usePostAppFavlistAddcomment = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAppFavlistAddcomment>>,
    TError,
    { id: string; data: BodyType<PostAppFavlistAddcommentBody> },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof postAppFavlistAddcomment>>,
  TError,
  { id: string; data: BodyType<PostAppFavlistAddcommentBody> },
  TContext
> => {
  const mutationOptions = getPostAppFavlistAddcommentMutationOptions(options)

  return useMutation(mutationOptions)
}
export const patchAppFavlistMarkcommentsasread = (id: string, options?: SecondParameter<typeof apiInstance>) => {
  return apiInstance<void>({ url: `/api/v1/lists/${id}/comments/mark-as-read`, method: "PATCH" }, options)
}

export const getPatchAppFavlistMarkcommentsasreadMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAppFavlistMarkcommentsasread>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAppFavlistMarkcommentsasread>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchAppFavlistMarkcommentsasread>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {}

    return patchAppFavlistMarkcommentsasread(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchAppFavlistMarkcommentsasreadMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAppFavlistMarkcommentsasread>>
>

export type PatchAppFavlistMarkcommentsasreadMutationError = ErrorType<void>

export const usePatchAppFavlistMarkcommentsasread = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAppFavlistMarkcommentsasread>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof apiInstance>
}): UseMutationResult<
  Awaited<ReturnType<typeof patchAppFavlistMarkcommentsasread>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getPatchAppFavlistMarkcommentsasreadMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getAppFavlistGetfavlistsnotifications = (
  options?: SecondParameter<typeof apiInstance>,
  signal?: AbortSignal
) => {
  return apiInstance<void>({ url: `/api/v1/lists/notifications`, method: "GET", signal }, options)
}

export const getGetAppFavlistGetfavlistsnotificationsQueryKey = () => {
  return [`/api/v1/lists/notifications`] as const
}

export const getGetAppFavlistGetfavlistsnotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>, TError, TData>
  request?: SecondParameter<typeof apiInstance>
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAppFavlistGetfavlistsnotificationsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>> = ({ signal }) =>
    getAppFavlistGetfavlistsnotifications(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAppFavlistGetfavlistsnotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>
>
export type GetAppFavlistGetfavlistsnotificationsQueryError = ErrorType<unknown>

export function useGetAppFavlistGetfavlistsnotifications<
  TData = Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAppFavlistGetfavlistsnotifications>>, TError, TData>
  request?: SecondParameter<typeof apiInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAppFavlistGetfavlistsnotificationsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
