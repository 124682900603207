import React from "react"

import { useGlobalState } from "@app/contexts"
import {
  DOWNLOAD_TO_GOOGLE_ERRORED,
  DOWNLOAD_TO_GOOGLE_STARTED,
  DOWNLOAD_TO_GOOGLE_SUCCEED,
  DOWNLOAD_TO_GOOGLE_TRIGGERED,
} from "@app/features/download/contstants"
import { DOWNLOAD_ERROR, DOWNLOAD_LOADING, DOWNLOAD_SUCCESS } from "@app/features/download/contstants"
import { useDownloadModal } from "@app/features/download/store/hooks"

const useDownloadToGoogleStatusListener = (submitButton: HTMLButtonElement, accessToken: any) => {
  const { $setDownloadToCloudStatus } = useDownloadModal()
  const { domain } = useGlobalState()

  React.useEffect(() => {
    const domainParts = domain.split(".")
    const protocol = window.location.href.split("/")[0]
    const mainDomain = domainParts.length <= 3 ? domainParts.slice(-2).join(".") : domainParts.slice(-3).join(".")

    function handleDownloadToGoogleStatus(event: any) {
      const isDomainValid = event.origin === `https://${mainDomain}`
      const isDownloadStarted = event?.data?.eventType?.type === DOWNLOAD_TO_GOOGLE_STARTED
      const isDownloadSucceed = event?.data?.eventType?.type === DOWNLOAD_TO_GOOGLE_SUCCEED
      const isDownloadErrored = event?.data?.eventType?.type === DOWNLOAD_TO_GOOGLE_ERRORED
      const isDownloadTriggered = event?.data?.eventType?.type === DOWNLOAD_TO_GOOGLE_TRIGGERED

      if (isDomainValid) {
        if (isDownloadTriggered) {
          accessToken.current = event?.data?.eventType?.accessToken
          submitButton.click()

          return
        }

        if (isDownloadStarted) {
          $setDownloadToCloudStatus(DOWNLOAD_LOADING)

          return
        }

        if (isDownloadSucceed) {
          $setDownloadToCloudStatus(DOWNLOAD_SUCCESS)

          return
        }

        if (isDownloadErrored) {
          $setDownloadToCloudStatus(DOWNLOAD_ERROR)

          return
        }
      }
    }

    window.addEventListener("message", handleDownloadToGoogleStatus)

    return () => window.removeEventListener("message", handleDownloadToGoogleStatus)
  }, [domain, $setDownloadToCloudStatus, submitButton, accessToken])
}

export { useDownloadToGoogleStatusListener }
