import { useMemo } from "react"

import { PrivateGalleryInterface, PublicGalleryInterface } from "@app/features/gallery/types"

// const AVARAGE_WEB_FORMAT_PHOTO_WEIGHT = 400000; // 400kb to bytes

export type DownloadFormat = "web" | "original"
type Scene = PrivateGalleryInterface["scenes"] | PublicGalleryInterface["scenes"]

const useScenesWeight = (
  scenes: Scene,
  downloadFormat: DownloadFormat
): [Nullable<number>, Map<string, Nullable<number>>] =>
  useMemo(() => {
    let totalWeight = 0
    const weightMap = new Map<string, Nullable<number>>()

    scenes.forEach((scene) => {
      if (downloadFormat === "original") {
        scene.mediaFiles.forEach(({ fileSize }) => {
          weightMap.set(scene.id, (weightMap.get(scene.id) || 0) + fileSize)
          totalWeight += fileSize
        })
      } else {
        weightMap.set(scene.id, null)
        totalWeight = null
      }
    })

    return [totalWeight, weightMap]
  }, [scenes, downloadFormat])

export { useScenesWeight }
