import axios, { AxiosError, AxiosRequestConfig } from "axios"

import { getHeaders } from "./utils/get-headers"
import { NEW_CLIENT_SIDE_BASE_URL, NEW_SERVER_SIDE_BASE_URL, REQUEST_TYPE } from "./constants"

export const AXIOS_INSTANCE = axios.create({
  baseURL: typeof window === "undefined" ? NEW_SERVER_SIDE_BASE_URL : NEW_CLIENT_SIDE_BASE_URL,
})

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...getHeaders(REQUEST_TYPE.CLIENT, null) }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const apiInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<{ data: T }> => {
  const source = axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled")
  }

  return promise
}

export type ErrorType<Error> = AxiosError<Error>

export type BodyType<BodyData> = BodyData
